/* @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css"); */

.image {
  width: 50px;
  height: 50px;
}

.navbar {
  background: black;
}

.navlink {
  font-family: "Bebas Neue", sans-serif;
  font-weight: bold;
}

.brand {
  font-family: "Bebas Neue", sans-serif;
  font-weight: bold;
  font-size: larger;
}
