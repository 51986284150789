.timelineSection {
  background: #d9d9d9;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgb(53, 53, 53);
  padding: 4em 5em !important;
}

.title {
  font-size: 2em;
  font-family: "Bebas Neue", sans-serif;
  font-weight: bold;
  /* line-height: 1.334;
  letter-spacing: 0em; */
  text-align: center;
}

h5,
h6 {
  padding-top: 0.25em;
}

.vertical-timeline-element-subtitle {
  color: #888171;
  font-weight: bold;
}

.vertical-timeline-element-title {
  font-weight: bold;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.date {
  font-size: 1em;
  color: rgb(0, 0, 0);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}

#description {
  margin: 1.5em 0 2em 0;
}
